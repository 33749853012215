'use client';
import { motion } from 'framer-motion';
import Image from "next/image";
import Link from "next/link";

const Logo = () => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
      className="flex items-center cursor-pointer bg-black text-white p-2 rounded font-semibold">
      <Link href="/" className="flex items-center">
        <Image
          src={'/images/LogoIcon.png'}
          alt="logo"
          width={40}
          height={40}
          className="mr-2"
        />
        <span className="text-[18px] pr-3">CasaKonnect</span>
      </Link>
    </motion.div>
  );
};

export default Logo;