import { Dialog, DialogContent } from '@mui/material';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  body?: React.ReactElement;
  footer?: React.ReactElement;
  actionLabel: string;
  disabled?: boolean;
  secondaryAction?: () => void;
  secondaryActionLabel?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  // onSubmit,
  title,
  body,
  footer,
  disabled,
  secondaryAction,
  secondaryActionLabel,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const router = useRouter();

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    if (disabled) {
      return;
    }

    setShowModal(false);
    onClose();
    router.refresh();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <DialogContent>
        <div className="container">
          <div
            className="fixed inset-0 z-50  flex justify-center items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-neutral-800/70"
            onClick={handleClose}
          >
            <div
              className="relative w-full md:w-4/6 lg:w-3/6 xl:w-2/5 my-6 mx-auto h-full lg:h-auto md:h-auto"
              onClick={(event) => event.stopPropagation()}
            >
              <div
                className={`translate duration-300 h-full ${
                  showModal ? 'translate-y-0' : 'translate-y-full'
                } ${showModal ? 'opacity-100' : 'opacity-0'}`}
              >
                <div className="translate h-full lg:h-auto md:h-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center p-6 rounded-t justify-center relative border-b-[1px]">
                    <button
                      onClick={handleClose}
                      className="p-1 border-0 hover:opacity-70 transition absolute left-9"
                    >
                      <IoMdClose size={18} />
                    </button>
                    <div className="text-lg font-semibold">{title}</div>
                  </div>
                  <div className="relative p-6 flex-auto">{body}</div>
                  <div className="flex flex-col gap-2 p-6">
                    <div className="flex flex-row items-center gap-4 w-full">
                      {secondaryAction && secondaryActionLabel && ''}
                    </div>
                    {footer}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
