import * as yup from 'yup';

export const RegisterValidation = yup.object().shape({
  username: yup.string().required("Le nom d'utilisateur est obligatoire"),
  email: yup
    .string()
    .email("Format d'email non valide")
    .required("L'adresse email est obligatoire."),
  password: yup
    .string()
    .required('Le mot de passe est obligatoire.')
    .min(8, 'Le mot de passe doit comporter au moins 8 caractères.')
    .matches(
      /[A-Z]/,
      'Le mot de passe doit contenir au moins une lettre majuscule.'
    )
    .matches(
      /[a-z]/,
      'Le mot de passe doit contenir au moins une lettre minuscule.'
    )
    .matches(
      /[^A-Za-z0-9]/,
      'Le mot de passe doit contenir au moins un caractère spécial.'
    ),
  verifyPassword: yup
    .string()
    .nullable()
    .oneOf([yup.ref('password'), null], 'Le mot de passe doit correspondre.')
    .required('Veuillez confirmer votre mot de passe.'),
});

export const LoginValidation = yup.object().shape({
  email: yup
    .string()
    .email("Format d'email non valide")
    .required("L'adresse email est requise"),
  password: yup.string().required('Le mot de passe est requis'),
});
