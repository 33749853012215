'use client';
import { useEffect, useState } from 'react';
import styles from './LaunchScreen.module.css';
import Image from 'next/image';

const LaunchScreen = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const hasSeenLaunchScreen = localStorage.getItem('hasSeenLaunchScreen');
    
    if (hasSeenLaunchScreen) {
      setShow(false);
      return;
    }

    const timer = setTimeout(() => {
      setShow(false);
      localStorage.setItem('hasSeenLaunchScreen', 'true');
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!show) return null;

  return (
    <div 
      className={styles.launchScreen} 
      aria-label="Écran de lancement CasaKonnect"
      role="status"
    >
      <Image src="/images/logoIcon.png" alt="Logo CasaKonnect" width={50} height={50} className={styles.logo} />
      <div className={styles.spinner} aria-label="Chargement en cours"></div>
      <button 
        onClick={() => setShow(false)} 
        className={styles.skipButton}
        aria-label="Passer l'écran de lancement"
      >
        Passer
      </button>
    </div>
  );
};

export default LaunchScreen;