import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/components/LaunchScreen.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/components/modals/auth/LoginModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/components/modals/auth/RegisterModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/components/regular/ToasterProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/components/ui/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/components/ui/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/build/repo/app/providers/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
