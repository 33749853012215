export default function getWhatsAppUrl(phoneNumber: string, message?: string) {
  const baseUrl = 'https://wa.me/';

  if (phoneNumber) {
    const sanitizedPhoneNumber = phoneNumber?.replace(/\D/g, ''); // Remove non-digit character
    let url = `${baseUrl}${sanitizedPhoneNumber}`;

    if (message) {
      const encodedMessage = encodeURIComponent(message);
      url += `?text=${encodedMessage}`;

      return url;
    }
  }
}
