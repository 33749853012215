'use client';
import Link from 'next/link';
import React from 'react';
import {motion} from 'framer-motion';
interface MenuItemProps {
  onClick?: () => void;
  label: string;
  href?: string;
  replace?: boolean;
  scroll?: boolean;
  prefetch?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  onClick,
  label,
  href,
  replace,
  scroll,
  prefetch,
}) => {


  const content = (
    <motion.div
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ ease: "easeInOut", duration: 0.75 }}

      onClick={onClick}
      className="px-4 py-3 hover:bg-neutral-100 transition font-semibold cursor-pointer"
    >
      {label}
    </motion.div>
  );

  return href ? (
    <Link href={href} replace={replace} scroll={scroll} prefetch={prefetch}>
      {content}
    </Link>
  ) : (
    content
  );
};

export default MenuItem;