import { createSlice } from '@reduxjs/toolkit';


export interface IItem {
    id: number;
    name: string | null;
    icon: undefined | string
}

const pointInterest: IItem[] = [];
const languages: IItem[] = [];

export const PropertySlice = createSlice({
    name: 'property',
    initialState: {
        country: null,
        province: null,
        city: null,
        suburb: null,
        district: null,
        pointsOfInterest: pointInterest,
        propertyListingType: null,
        property_type: null,
        uploading: false,
        languages: languages,
        education_level: null,
        service_category: null,
        payment_method: null
    },
    reducers: {
        setCountry: (state, action) => {
            state.country = action.payload;
        },

        setProvince: (state, action) => {
            state.province = action.payload;
        },

        setCity: (state, action) => {
            state.city = action.payload;
        },

        setSuburb: (state, action) => {
            state.suburb = action.payload;
        },

        setDistrict: (state, action) => {
            state.district = action.payload;
        },

        setPaymentMethod: (state, action) => {
            state.payment_method = action.payload;
        },
        setServiceCategory: (state, action) => {
            state.service_category = action.payload;
        },

        setEducationLevel: (state, action) => {
            state.education_level = action.payload;
        },

        setPointsOfInterest: (state, action) => {
            state.pointsOfInterest = Array.from(new Set([...state.pointsOfInterest, action.payload]));
        },

        setLanguages: (state, action) => {
            state.languages = Array.from(new Set([...state.languages, action.payload]));
        },
        setPropertyType: (state, action) => {
            state.property_type = action.payload;
        },

        setPropertyListingType: (state, action) => {
            state.propertyListingType = action.payload;
        },

        setUploading: (state, action) => {
            state.uploading = action.payload;
        },
    },
});

export const { setCountry, setProvince, setCity, setSuburb, setDistrict, setPointsOfInterest,
    setPropertyType, setUploading, setPropertyListingType, setEducationLevel,
    setLanguages, setServiceCategory, setPaymentMethod
} = PropertySlice.actions

export const _country = (state:any) => state.property?.country
export const _province = (state:any) => state.property?.province
export const _city = (state:any) => state.property?.city
export const _suburb = (state:any) => state.property?.suburb
export const _district = (state:any) => state.property?.district
export const _property_type = (state:any) => state.property?.property_type
export const _propertyListingType = (state:any) => state.property?.propertyListingType
export const _pointsOfInterest = (state:any) => state.property?.pointsOfInterest
export const _uploading = (state:any) => state.property?.uploading
export const _languages = (state:any) => state.property?.languages
export const _education_level = (state:any) => state.property?.education_level
export const _service_category = (state:any) => state.property?.service_category
export const _payment_method = (state:any) => state.property?.payment_method

export default PropertySlice.reducer
