import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { BaseService } from "../services/BaseService";
import AppSlice from "./slices/AppSlice";
import AuthSlice from "./slices/AuthSlice";
import PropertySlice from "./slices/PropertySlice";


export const store = configureStore({
  reducer: {
    app: AppSlice,
    auth: AuthSlice,
    property: PropertySlice,
    [BaseService.reducerPath]: BaseService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(BaseService.middleware),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)