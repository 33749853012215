/* eslint-disable no-unused-vars */
'use client';
import AlertError from '@/app/components/errors/AlertError';
import useLoginModal from '@/app/hooks/useLoginModal';
import UseRegisterModal from '@/app/hooks/useRegisterModal';
import { registerSuccess } from "@/app/providers/slices/AuthSlice";
import { useRegisterMutation } from "@/app/services/auth/AuthService";
import { Button } from '@nextui-org/react';
import { Formik } from 'formik';
import { useState } from "react";
import { toast } from 'react-hot-toast';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import Modal from './Modal';
import { RegisterValidation } from './validations/AuthenticationValidation';


const RegisterModal = () => {
  const registerModal = UseRegisterModal();
  const loginModal = useLoginModal()
  const [registerMutation, isLoading] = useRegisterMutation();
  const [loading, setLoading] = useState(isLoading.isLoading);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const toggleVerifyPasswordVisibility = () => setShowVerifyPassword(!showVerifyPassword);

  const handleModalSwitch = () => {
    registerModal.onClose()
    loginModal.onOpen()
  }

  const submitForm = async (values: any) => {
    setLoading(true);
    try {
      const requestData = {
        username: values.username,
        email: values.email, // Assuming email corresponds to identifier
        password: values.password
      };
      registerMutation(requestData)
        .unwrap()
        .then((payload: any) => {
          dispatch(registerSuccess({ user: payload.user, token: payload.token }));
          toast.success("Congratulation you have successfully login !!", payload.user.email);
          registerModal.onClose();
        })
        .then(() => {
          window.location.href = '/account';
        })
        .catch((error: any) => {
          if (error.response && error.response.data && error.response.data.error) {
            const errorMessage = error.response.data.error.message;
            toast.error(errorMessage);
          } else {
            toast.error('An error occurred. Please try again later.');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const body = (
    <div className="flex flex-col gap-4">
      <div className='text-center'>
        <small>Utilisez votre vrai nom ou le nom de votre entreprise si vous etes une entreprise.</small>
      </div>
      <div className="flex justify-center">

        <div className="w-full max-w-md">
          <Formik
            initialValues={{
              username: '',
              email: '',
              password: '',
              verifyPassword: '',
            }}
            onSubmit={(values, actions) => {
              submitForm(values);
              actions.setSubmitting(false)
            }}
            validationSchema={RegisterValidation}

          >
            {({ handleSubmit, handleChange, handleBlur, isValid, errors, touched }) => (
              <form className="space-y-3" onSubmit={handleSubmit}>
                <input
                  type='username'
                  placeholder="Nom complet / entreprise"
                  className="input-field text-sm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="username"
                  required
                  style={{ borderColor: touched.username && errors.username ? 'red' : 'inherit' }}
                />
                {touched.username && errors.username && (
                  <AlertError message={errors.username} />
                )}
                <input
                  type='email'
                  placeholder="Adresse email"
                  className="input-field text-sm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  required
                  style={{ borderColor: touched.email && errors.email ? 'red' : 'inherit' }}
                />
                {touched.email && errors.email && (
                  <AlertError message={errors.email} />
                )}
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Mot de passe"
                    className="input-field text-sm pr-10"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    required
                    style={{ borderColor: touched.password && errors.password ? 'red' : 'inherit' }}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                  </button>
                </div>
                {touched.password && errors.password && (
                  <AlertError message={errors.password} />
                )}
                <div className="relative">
                  <input
                    type={showVerifyPassword ? 'text' : 'password'}
                    placeholder="Confirmer votre mot de passe"
                    className="input-field text-sm pr-10"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="verifyPassword"
                    required
                    style={{ borderColor: touched.verifyPassword && errors.verifyPassword ? 'red' : 'inherit' }}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none"
                    onClick={toggleVerifyPasswordVisibility}
                  >
                    {showVerifyPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                  </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  {touched.verifyPassword && errors.verifyPassword && (
                    <AlertError message={errors.verifyPassword} />
                  )}
                  <Button
                    isLoading={loading}
                    color="primary"
                    style={btnStyle}
                    type='submit'>
                    S'inscrire
                  </Button>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </div>
    </div>
  );

  const modalProps = {
    isOpen: registerModal.isOpen,
    title: 'Inscription',
    actionLabel: 'Register',
    onClose: registerModal.onClose,
    body,
    footer: (
      <div className="flex flex-col gap-4 mt-3">
        <hr />
        <div className="text-neutral-500 text-center mt-4 font-light">
          <div className="justify-center flex flex-row items-center gap-2">
            <h2>Vous avez déjà un compte?</h2>
            <h2
              onClick={handleModalSwitch}
              className="text-neutral-800 cursor-pointer hover:underline font-bold"
            >
              Connectez-vous.
            </h2>
          </div>
        </div>
      </div>
    ),
  };

  return <Modal onSubmit={function (): void {
    throw new Error('Function not implemented.');
  }} {...modalProps} />;
};

export default RegisterModal;
const btnStyle = { backgroundColor: 'black', color: 'white', marginTop: '5px' }