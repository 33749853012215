import { AuthSlice } from "@/app/providers/slices/AuthSlice";
import { PropertySlice } from "@/app/providers/slices/PropertySlice";
import { BaseService } from "@/app/services/BaseService";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { AppSlice } from "../app/providers/slices/AppSlice";

export const store = configureStore({
  reducer: {
    appSlice: AppSlice.reducer,
    authSlice: AuthSlice.reducer,
    propertySlice: PropertySlice.reducer,
    [BaseService.reducerPath]: BaseService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(BaseService.middleware),
});
setupListeners(store.dispatch)

