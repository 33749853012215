import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const BaseService = createApi({
  reducerPath: 'BaseService',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      headers.set('Authorization', `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`);
      return headers;
    },

  }),
  endpoints: () => ({}),
})