import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCookie } from 'cookies-next';

const setAuthCookie = (token: string, name: string) => {
  const toBase64 = Buffer.from(token).toString('base64');

  setCookie(name, toBase64, {
    
    path: '/',
    ...({maxAge: 30 *24 *60*60} as any),
    // more security options here
    // sameSite: 'strict',
    // httpOnly: true,
    // secure: process.env.NODE_ENV === 'production',
  });
};

interface AuthState {
  isLoading: boolean;
  user: { [key: string]: any } | null; // Change 'userInfo' to 'user'
  userToken: string | null;
  error: string | null;
  success: boolean;
  isAuthenticated: boolean;
}

const storedToken =
  typeof window !== 'undefined' ? localStorage.getItem('authToken') : null;
const storedUser =
  typeof window !== 'undefined'
    ? JSON.parse(localStorage.getItem('authUser') ?? '{}')
    : null;

const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  user: storedUser,
  userToken: storedToken,
  error: null,
  success: false,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (
      state,
      action: PayloadAction<{ user: any; token: string }>
    ) => {
      try {
        state.isAuthenticated = true;
        state.isLoading = false;
        state.user = action.payload.user;
        state.userToken = action.payload.token;
        state.error = null;
        state.success = true;

        // Store user data and token in local storage upon successful login
        localStorage.setItem('authToken', action.payload.token);
        localStorage.setItem('authUser', JSON.stringify(action.payload.user));
        setAuthCookie(action.payload.token, 'auth_token');
      } catch (error) {
        // Handle any errors that occur during the loginSuccess reducer execution
        console.error('Error in loginSuccess reducer:', error);
        // You can add specific error handling here if needed
      }
    },
    registerSuccess: (
      state,
      action: PayloadAction<{ user: any; token: string }>
    ) => {
      try {
        state.isAuthenticated = true;
        state.isLoading = false;
        state.user = action.payload.user;
        state.userToken = action.payload.token;
        state.error = null;
        state.success = true;

        // Store user data and token in local storage upon successful login
        localStorage.setItem('authToken', action.payload.token);
        localStorage.setItem('authUser', JSON.stringify(action.payload.user));
        setAuthCookie(action.payload.token, 'auth_token');
      } catch (error) {
        // Handle any errors that occur during the loginSuccess reducer execution
        console.error('Error in loginSuccess reducer:', error);
        // You can add specific error handling here if needed
      }
    },
    loginfailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      state.isLoading = false;
      localStorage.removeItem('authToken');
      localStorage.removeItem('authUser');
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },

    // Add other reducers as needed
  },
});

export const { registerSuccess, loginSuccess, loginfailure, logout, setUser } =
  AuthSlice.actions;
export default AuthSlice.reducer;
