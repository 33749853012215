'use client';
import { store } from '@/redux/store';
import {
  faCar,
  faCirclePlus,
  faHandshakeAngle,
  faHouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import {motion} from 'framer-motion';

interface MenuItem {
  id: number;
  name: string;
  path: string;
  icon?: React.ReactNode; // Add an optional icon property
}

const Menu: React.FC = () => {
  const router = useRouter();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState<any | null>(null);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'Ajouter Propriété',
      path: '/create-property',
      icon: (
        <p className=" p-1 rounded-full">
          {' '}
          <span className="text-3xl">+</span>{' '}
          <FontAwesomeIcon icon={faHouse} className="text-2xl" />
        </p>
      ), // Add the icon component
    },
    {
      id: 2,
      name: 'Ajouter Véhicule',
      path: '/create-vehicle',
      icon: (
        <p className=" p-1 rounded-full">
          {' '}
          <span className="text-3xl">+</span>{' '}
          <FontAwesomeIcon icon={faCar} className="text-2xl" />
        </p>
      ),
    },
    {
      id: 3,
      name: 'Ajouter Service',
      path: '/create-service',
      icon: (
        <p className="p-1 rounded-full">
          {' '}
          <span className="text-3xl">+</span>
          <FontAwesomeIcon icon={faHandshakeAngle} className="text-2xl" />
        </p>
      ), // Add the icon component
    },
  ];

  useEffect(() => {
    // Load user data from local storage on component mount
    const userData = store.getState()?.authSlice?.user ?? {};
    setUser(userData);
    setAuthenticated(userData && userData.email);
  }, []);

  const handleItemClick = (path: string) => {
    if (isAuthenticated) {
      router.push(path);
    } else {
      onOpen();
    }
  };

  return (
    <motion.div
    initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.75 }}
    className="w-full hidden lg:block md:w-auto py-2 rounded-full shadow-sm hover:shadow-md transition cursor-pointer">
      <ul className="flex flex-row items-center justify-between">
        {menuItems.map((item) => (
          <li
            key={item.id}
            onClick={() => handleItemClick(item.path)}
            className="hover:text-[#64748b] text-sm font-semibold px-6 cursor-pointer hover:scale-105 transition-all ease-in-out flex items-center "
          >
            <span className="md:hidden">{item.icon}</span>
            <span className="hidden md:inline-block  ">
              {' '}
              <FontAwesomeIcon icon={faCirclePlus} /> {item.name}
            </span>
          </li>
        ))}
      </ul>
      <Modal isOpen={isOpen} onClose={onClose} placement="center">
        <ModalContent>
          <ModalHeader>Information</ModalHeader>
          <ModalBody>
            Veuillez vous connecter ou vous inscrire avant de créer une annonce
            ou acceder au tableau de bord.
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </motion.div>
  );
};

export default Menu;
