/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
'use client';

import { Button } from '@nextui-org/react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

const ForgotPasswordModal = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const apiKey = process.env.NEXT_PUBLIC_API_KEY;
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${apiKey}`);
  headers.append('Accept', 'application/json');

  const handleForgotPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (!email) {
      toast.error('Please enter your email');
      return;
    }
    setLoading(true); // Set loading to true before the request
    try {
      const response = await fetch(`${apiUrl}auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`, // Include the Authorization header
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success('Password reset email sent.');
      } else {
        toast.error('Failed to send password reset email.');
      }
    }
    catch (error) {
      toast.error("Une erreur s'est produite. Veuillez reessayer plus tard.");
    } finally {
      setLoading(false); // Reset loading state after the request
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="pb-8">
            <small>
              S'il vous plait entrez votre adresse email. Nous vous enverrons un
              e-mail avec un lien pour reinitialiser votre mot de passe
            </small>
          </div>
          <form className="space-y-3" onSubmit={handleForgotPassword}>
            <input
              type="email"
              placeholder="Email"
              className="input-field text-sm"
              name="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              required
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button isLoading={loading} style={btnStyle} type="submit">
                Envoyez un lien
              </Button>
            </div>

            <p className="h-4 text-red-500 text-lg text-center mt-20">
              {errorMessage}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
const btnStyle = { backgroundColor: 'black', color: 'white' };
