/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
'use client';
import AlertError from '@/app/components/errors/AlertError';
import UserLoginModal from '@/app/hooks/useLoginModal';
import UseRegisterModal from '@/app/hooks/useRegisterModal';
import { loginSuccess } from '@/app/providers/slices/AuthSlice';
import { useLoginMutation } from '@/app/services/auth/AuthService'; // Adjust the import
import { Button } from '@nextui-org/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import ForgotPasswordModal from './ForgotPasswordModal';
import Modal from './Modal';
import { LoginValidation } from './validations/AuthenticationValidation';

const LoginModal = () => {
  const loginModal = UserLoginModal();
  const registerModal = UseRegisterModal();

  const [loginMutation, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleModalSwitch = () => {
    loginModal.onClose();
    registerModal.onOpen();
  };

  const submitForm = async (values: any) => {
    setLoading(true);
    try {
      const requestData = {
        identifier: values.email,
        password: values.password,
      };

      loginMutation(requestData)
        .unwrap()
        .then((payload) => {
          dispatch(loginSuccess({ user: payload.user, token: payload.jwt }));
          toast.success(
            'Congratulation you have successfully login !!',
            payload.user.email
          );

          // Add success slice here to
          loginModal.onClose();
          window.location.href = '/account';
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error.message;
            setErrorMessage(
              "Veuillez saisir des informations d'identification valides."
            );
            toast.error(errorMessage);
          } else {
            console.error(error);
            toast.error(
              "Une erreur s'est produite. Veuillez réessayer plus tard."
            );
            setErrorMessage(
              "Veuillez saisir des informations d'identification valides."
            );
          }
        })
        .finally(() => {
          setLoading(false); // Set loading state to false regardless of success or failure
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        });
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const body = (
    <div className="flex flex-col gap-4">
      <div className="flex justify-center">
        {showForgotPassword ? (
          <ForgotPasswordModal />
        ) : (
          <div className="w-full max-w-md">
            <p className="pb-8">
              <small>
                Utilisez vos identifiants CasaKonnect pour vous connecter.
              </small>
            </p>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={(values, actions) => {
                submitForm(values);
                actions.setSubmitting(false);
              }}
              validationSchema={LoginValidation}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                isValid,
                errors,
                touched,
              }) => (
                <form className="space-y-3" onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Email"
                    className="input-field text-sm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    required
                    style={{
                      borderColor:
                        touched.email && errors.email ? 'red' : 'inherit',
                    }}
                  />
                  {touched.email && errors.email && (
                    <AlertError message={errors.email} />
                  )}
                  <input
                    type="password"
                    placeholder="Password"
                    className="input-field text-sm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    required
                    style={{
                      borderColor:
                        touched.password && errors.password ? 'red' : 'inherit',
                    }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {touched.password && errors.password && (
                      <AlertError message={errors.password} />
                    )}
                    <Button isLoading={loading} style={btnStyle} type="submit">
                      Connexion
                    </Button>
                  </div>
                  <p className="h-4 text-red-500 text-lg text-center mt-20">
                    {errorMessage}
                  </p>
                  <div
                    className="text-neutral-500 text-center mb-8 font-light"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    <p className="flex justify-center items-center gap-2">
                      <span> Mot de passe oublié?</span>
                      <span className="text-black cursor-pointer hover:underline">
                        le réinitialisez maintenant
                      </span>
                    </p>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );

  const modalProps = {
    isOpen: loginModal.isOpen,
    title: showForgotPassword ? 'Mot de passe oublié' : 'Se Connecter',
    actionLabel: 'Login',
    onClose: loginModal.onClose,
    body,
    footer: (
      <>
        <hr />
        <div className="text-neutral-500 text-center mt-4 font-light">
          <div className="justify-center flex flex-row items-center gap-2">
            <div>Vous n'avez pas de compte CasaKonnect?</div>
            <div
              onClick={handleModalSwitch}
              className="text-black cursor-pointer hover:underline"
            >
              En créer un maintenant
            </div>
          </div>
        </div>
      </>
    ),
  };

  return <Modal onSubmit={() => {}} {...modalProps} />;
};

export default LoginModal;
const btnStyle = { backgroundColor: 'black', color: 'white', marginTop: '5px' };
