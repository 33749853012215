'use client';
import Container from '../../Container';
import Logo from './Logo';
import Menu from './Menu';
import UserMenu from './UserMenu';
import {motion} from 'framer-motion';

const Navbar = () => {
  return (
    <motion.div
    initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.75 }}
    className="fixed w-full bg-white z-50 shadow-sm">
      <div className="py-4 border-b-[1px]">
        <Container>
          <div className="flex flex-row items-center justify-between gap-3 md:gap-0">
            <Logo />
            <Menu />
            <UserMenu />
          </div>
        </Container>
      </div>
    </motion.div>
  );
};

export default Navbar;
