'use client';
import { Button } from '@nextui-org/react';
import React from 'react';

type ButtonColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'black';

  // Define the colors expected by @NextUI
type NextUIButtonColor = 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger';

interface CustomButtonProps {
  label: string;
  color: ButtonColor;
  size?: 'sm' | 'md' | 'lg';
  radius?: 'full' | 'lg' | 'md' | 'sm' | 'none';
  height?: string;
  borderWidth?: string;
  borderColor?: string;
  hoverCursor?: string; // Adding hoverCursor prop
  variant?:
    | 'solid'
    | 'faded'
    | 'bordered'
    | 'light'
    | 'flat'
    | 'ghost'
    | 'shadow';
  onClick: () => void;
  isLoading?: boolean;
}

// Function to map custom colors to NextUI acceptable colors 
const mapButtonColor = (color: ButtonColor): NextUIButtonColor => {
  if (color === 'black'){
    // Map 'black' to a supported color , e.g. 'default'
    return 'default';
  }
  return color;
};

const NextUIButton: React.FC<CustomButtonProps> = ({
  label,
  color,
  size = 'md',
  radius = 'md',
  height,
  borderWidth,
  borderColor,
  hoverCursor = 'pointer', // Default hover cursor is pointer
  variant = 'solid',
  onClick,
}) => {
  const mappedColor = mapButtonColor(color); // Map the color to a supported NextUI color 
  return (
    <Button
      color={mappedColor}
      size={size}
      radius={radius}
      variant={variant}
      onClick={onClick}
      style={{
        height,
        borderWidth,
        borderColor,
        cursor: hoverCursor, // Applying hoverCursor prop
      }}
    >
      {label}
    </Button>
  );
};

export default NextUIButton;
