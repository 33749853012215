import * as React from 'react';

export interface IAlertErrorProps {
    message:string
}

export default function AlertError(props: IAlertErrorProps) {
    return (
        <span className=" text-red-500 text-sm mt-2">
            {props.message}
        </span>
    );
}
