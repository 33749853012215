import { BaseService } from "../BaseService";


const AuthService = BaseService.injectEndpoints({
  endpoints: (build) => ({

    register: build.mutation({
      query: (data) => ({
        url: '/auth/local/register',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*'
        }
      }),
    }),

    login: build.mutation({
      query: (data) => ({
        url: '/auth/local',
        method: 'POST',
        body: data, 
        headers:{
          'Content-Type':'application/json'
        }
      }),
    }),

    getCurrentUser: build.query({
      query: (id) => `users/${id}?populate=*`
    }),

    updateUser: build.mutation({
      query: ({id, data}) => ({
        url: `/users/${id}?populate=*`,
        method: 'PUT',
        body: data,
        headers:{
          'Content-Type':'application/json',
          'Accept':'application/json'
        }
      }),
    }),

  }),
  overrideExisting: false,
})

export const {
  useRegisterMutation,
  useLoginMutation,
  useUpdateUserMutation,
  useGetCurrentUserQuery
} = AuthService





